import React from 'react';
import App from 'next/app';

import * as Sentry from '@sentry/browser';

import '@styles/scss/core.scss';
import '@styles/scss/app.scss';
import '@styles/scss/swiper.scss';

import { DefaultSeo, LogoJsonLd, LocalBusinessJsonLd } from 'next-seo';
// import your default seo configuration
import SEO, { logoJSON, localBusinessJSON, socialJson } from 'next-seo.config';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
// import AlertTemplate from 'react-alert-template-basic';
import AlertTemplate from '@views/components/alert-template';
// optional configuration
const alertOptions = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: '30px',
};

import Router from 'next/router';
// import { pageview } from '@views/modules/gtag';
// import { GTMScript, GTMNoScript } from '@views/modules/gtag/gtm';
// import { gtmPageView } from '@views/modules/gtag/utils';
// import { trackPageView } from '@views/modules/facebook';
import NProgress from 'nprogress';
import { useConfig } from '@server/lib/config';
// import WindowResize from '@views/modules/common/components/window-resize';

Router.events.on('routeChangeStart', (url) => {
  console.log(`Loading: ${url}`);
  NProgress.start();
});
Router.events.on('routeChangeError', () => NProgress.done());
Router.events.on('routeChangeComplete', (url: string) => {
  NProgress.done();
  // gtmPageView(url);
  // trackPageView();
});

function initSentry() {
  const { SENTRY_DNS } = useConfig();
  Sentry.init({ dsn: SENTRY_DNS, debug: false });
}

initSentry();

class MyApp extends App {
  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });

      Sentry.captureException(error);
    });

    super.componentDidCatch(error, errorInfo);
  }

  render() {
    const { Component, pageProps } = this.props;
    const { NO_SEO } = useConfig();
    const disabledSEO = NO_SEO ? { nofollow: true, noindex: true } : {};

    return (
      <AlertProvider template={AlertTemplate} {...alertOptions}>
        <DefaultSeo {...SEO} {...disabledSEO} />
        <LogoJsonLd {...logoJSON} />
        <LocalBusinessJsonLd {...localBusinessJSON} />
        <Component {...pageProps} />
        {/* <WindowResize /> */}
      </AlertProvider>
    );
  }
}

export default MyApp;
